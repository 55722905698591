<template>
  <div class="repurchase-overview">
    <PageTitle title="首購 vs 回購" hideBtn />
    <div class="updateAt">
      <p>{{ updateAt }}</p>
      <p>{{ updateTime }}</p>
    </div>
    <div class="flex items-center px-2 pb-[34px] gap-6">
      <div
        class="page-tab inActive"
        @click="$router.push({ name: 'FirstPurchaseOverview' })"
      >
        首購客
      </div>
      <div class="page-tab active">回購客</div>
    </div>
    <section class="flex flex-col gap-10">
      <TotalPurchaseStatistics class="chart-block" @getTime="getTime" />
      <TotalOrderStatistics class="chart-block" />
      <AverageConsumptionStatistics class="chart-block" />
      <AverageOrderStatistics class="chart-block" />
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import dayjs from 'dayjs'
import TotalPurchaseStatistics from './components/TotalPurchaseStatistics/TotalPurchaseStatistics.vue'
import TotalOrderStatistics from './components/TotalOrderStatistics/TotalOrderStatistics.vue'
import AverageConsumptionStatistics from './components/AverageConsumptionStatistics/AverageConsumptionStatistics.vue'
import AverageOrderStatistics from './components/AverageOrderStatistics/AverageOrderStatistics.vue'

export default defineComponent({
  name: 'RepurchaseOverview',
  components: {
    TotalPurchaseStatistics,
    TotalOrderStatistics,
    AverageConsumptionStatistics,
    AverageOrderStatistics,
  },
  setup () {
    const time = ref(null)

    const updateAt = computed(() => {
      return `數據截止日：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD') : '-'
      }`
    })
    const updateTime = computed(() => {
      return `數據更新時間：${
        time.value ? dayjs(time.value).format('YYYY/MM/DD HH:mm:ss') : '-'
      }`
    })
    const getTime = (e) => {
      time.value = e
    }
    return {
      getTime,
      updateAt,
      updateTime,
    }
  },
})
</script>

<style lang="postcss" scoped>
.updateAt {
  @apply flex justify-end gap-[20px];
  @apply text-gray-60;
  @apply pb-[34px];
}
.chart-block {
  @apply bg-[#fff];
  @apply p-5;
  @apply rounded;
}
.page-tab {
  @apply px-3 py-2;
  @apply border-b-2 border-transparent;
  @apply cursor-pointer;
}
.active {
  @apply text-primary-100;
  @apply border-primary-100;
}
.inActive {
  @apply text-gray-80;
}
</style>
